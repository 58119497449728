.ReactModalPortal div {
  z-index: 10;
}
.relative-portal div {
  z-index: 100;
}

.relative-portal .react-date-picker__inputGroup {
  min-width: 0;
}

ol.breadcrumb {
  background: none;
  vertical-align: middle;
  margin: 0px;
}

input.decimal-widget[type="number"]::-webkit-inner-spin-button {
  margin-left: 5px;
}

input.decimal-widget[type="number"] {
  padding-right: 0.75em;
}

.f-datagrid
  .fixedDataTableLayout_header
  .public_fixedDataTableCell_cellContent {
  text-align: center;
  cursor: pointer;
}

.f-sideaction .public_fixedDataTableCell_cellContent {
  text-align: "center";
}

.f-topaction .public_fixedDataTableCell_cellContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.breadcrumb {
  padding: 0px;
}

#hamburger-toggle::after {
  display: none;
}

.fixedDataTableCellLayout_wrap3 {
  vertical-align: top;
}

.form-group label {
  font-weight: bold;
}

.f-datagrid .fixedDataTableCellLayout_main,
.f-datagrid .fixedDataTableCellGroupLayout_cellGroup,
.f-datagrid .fixedDataTableRowLayout_main {
  overflow: unset;
}

.field-row {
  display: flex;
}

.field-row > * + * {
  padding-left: 1em;
}

.field-row > * {
  flex-grow: 1;
  flex-basis: 0;
}

.field-row.no-expand > * {
  flex-grow: 0;
  flex-basis: auto;
}

.tab-content > .active {
  display: flex !important;
}

.tab-content,
.tab-pane {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
}

#sign-in-button {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}


.rich-editor {
  flex-grow: 1;
}

.editor-container {
  border-radius: 2px;
  width: 100%;
  flex-grow: 1;
  color: #000;
  position: relative;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; 
  border: solid 2px #eee;
}

.editor-inner {
  background: #fff;
  margin: 1em;
  position: relative;
}

.editor-toolbar {
  display: flex;
  margin-bottom: 1px;
  background: #eee;
  padding: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  vertical-align: middle;
}

.editor-toolbar button.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  vertical-align: middle;
}

.editor-toolbar button.toolbar-item:disabled {
  cursor: not-allowed;
}

.editor-toolbar button.toolbar-item.spaced {
  margin-right: 2px;
}

.editor-toolbar button.toolbar-item i.format {
  background-size: contain;
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-top: 2px;
  vertical-align: -0.25em;
  display: flex;
  opacity: 0.6;
}

.editor-toolbar button.toolbar-item:disabled i.format {
  opacity: 0.2;
}

.editor-toolbar button.toolbar-item.active {
  background-color: rgba(193, 202, 220, 0.3);
}

.editor-toolbar button.toolbar-item.active i {
  opacity: 1;
}

.editor-toolbar .toolbar-item:hover:not([disabled]) {
  background-color: #eee;
}

.editor-toolbar .divider {
  width: 1px;
  background-color: #ddd;
  margin: 0 4px;
}

.editor-toolbar select.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 70px;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
}

.editor-toolbar select.code-language {
  text-transform: capitalize;
  width: 130px;
}

.editor-toolbar .toolbar-item .text {
  display: flex;
  line-height: 20px;
  width: 200px;
  vertical-align: middle;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
  width: 70px;
  overflow: hidden;
  height: 20px;
  text-align: left;
}

.editor-toolbar .toolbar-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}

.editor-toolbar i.chevron-down {
  margin-top: 3px;
  width: 16px;
  height: 16px;
  display: flex;
  user-select: none;
}

.editor-toolbar i.chevron-down.inside {
  width: 16px;
  height: 16px;
  display: flex;
  margin-left: -25px;
  margin-top: 11px;
  margin-right: 10px;
  pointer-events: none;
}
[contenteditable] {
  outline: 0px solid transparent;
}

.link-editor {
  position: absolute;
  z-index: 100;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  max-width: 300px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: opacity 0.5s;
}

.link-editor .link-input {
  display: block;
  width: calc(100% - 24px);
  box-sizing: border-box;
  margin: 8px 12px;
  padding: 8px 12px;
  border-radius: 15px;
  background-color: #eee;
  font-size: 15px;
  color: rgb(5, 5, 5);
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
}

.link-editor div.link-edit {
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.link-editor .link-input a {
  color: rgb(33, 111, 219);
  text-decoration: none;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  text-overflow: ellipsis;
}

.link-editor .link-input a:hover {
  text-decoration: underline;
}

.link-editor .button {
  width: 20px;
  height: 20px;
  display: inline-block;
  padding: 6px;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 2px;
}

.link-editor .button.hovered {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #eee;
}

.link-editor .button i,
.actions i {
  background-size: contain;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: -0.25em;
}

.editor-dropdown {
  z-index: 5;
  display: block;
  position: absolute;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  min-width: 100px;
  min-height: 40px;
  background-color: #fff;
}

.editor-dropdown .item {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
  min-width: 268px;
}

.editor-dropdown .item .active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.editor-dropdown .item:first-child {
  margin-top: 8px;
}

.editor-dropdown .item:last-child {
  margin-bottom: 8px;
}

.editor-dropdown .item:hover {
  background-color: #eee;
}

.editor-dropdown .item .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  width: 200px;
}

.editor-dropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 12px;
  line-height: 16px;
  background-size: contain;
}

#block-controls button:hover {
  background-color: #efefef;
}

#block-controls button:focus-visible {
  border-color: blue;
}

#block-controls span.block-type {
  background-size: contain;
  display: block;
  width: 18px;
  height: 18px;
  margin: 2px;
}

.link-report-modal .modal-dialog {
  max-width: initial;
}

.link-report-tabs {
  height: 75vh;
  width: 90vw;
  display: flex;
  flex-direction: column;
}
.link-report-tabs .tab-content {
  --height: 100%;
}